import { USERINFO_MUTATION } from "../mutation-types.js" 
export default {
    namespaced: true,
    state(){
        return {
            userInfo:null
        }
    },
    getters:{
        isLogin(state){
            return state.userInfo&&Object.keys(state.userInfo).length>0;
        }
    },
    actions:{
        userLogin(context){
            console.log("context",context)
            console.log("context.rootState",context.rootState)
        }
    },
    mutations:{
        [USERINFO_MUTATION](state,userInfo){
            state.userInfo = userInfo;
        }
    }
}