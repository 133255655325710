class EventBus {
	constructor(){
        this.eventList = {}//收集存放事件,相当于收集订阅信息,调度中心
	}
	//订阅
	on(name,fn){
        console.log(`订阅了${name}事件`);
		this.eventList[name] = this.eventList[name] || []
		this.eventList[name].push(fn)
	}
	//发布
	emit(name,data){
        console.log(`触发了${name}事件`);
		//判断是否订阅
		if(this.eventList[name]){
			this.eventList[name].forEach((fn)=>{
				fn(data)
			})
		}else{
            console.warn("bus中暂无该事件，请检查事件类型")
        }
	}
	//取消
	off(name){
        console.log(`卸载了${name}事件`);
		if (this.eventList[name]) {
		  delete this.eventList[name]
		}
	}
}
//导出Bus的实例
export default new EventBus()