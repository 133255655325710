export default [
    {
        path:"/",
        component:() => import('@/views/homePage/homePage.vue'),
        
    },
    {
        path:"/homePage",
        name:"HomePage",
        component:() => import('@/views/homePage/homePage.vue'),
        children:[
            
        ]
    },
    {
        path:"/dzbz",
        name:"Dongzuobuzhuo",
        component:() => import('@/views/dongzuobuzhuo/dongzuobz.vue'),
    },
    {
        path:"/szzb",
        name:"Szzb",
        component:() => import('@/views/shuzizb/shuzizb.vue'),
    },
    {
        path:"/fwzc",
        name:"Fwzc",
        component:() => import('@/views/fuwuzhichi/fuwuzhichi.vue'),
    },
    {
        path:"/about",
        name:"About",
        component:() => import('@/views/about/about.vue'),
    },
    {
        path:"/down",
        name:"Down",
        component:() => import('@/views/down/down.vue'),
    }
]