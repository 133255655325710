import { createApp } from 'vue'
import App from './App.vue'

import store from "./store/index.js"
import router from "./route/index.js"
import "@/assets/css/reset.css"
import "@/assets/css/common.css"
import ElementPlus from 'element-plus'
// import zhCN from "@/assets/js/langurage.js";
import 'element-plus/dist/index.css'
import EventBus from "@/eventBus/EventBus"
import { colorLog } from "@/utils/fn.js"
import {pxtoRem} from "@/assets/js/pxToRem.js"
pxtoRem();
window.colorLog = colorLog;
const app = createApp(App);
// app.use(ElementPlus,{ locale: zhCN })
app.use(ElementPlus)
app.use(store)
let vueProto = app.config.globalProperties;
vueProto.$bus = EventBus;
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 动态注册原型变量
vueProto.$registProp = function(name, val) {
    name && (vueProto[name] = val);
  }
app.use(router).mount('#app')
