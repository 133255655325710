import { createRouter,createWebHistory,createWebHashHistory } from "vue-router"
import store from "@/store/index"
import routes from "./route"
const router = createRouter({
    history:createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    
    // let { userInfo } = store.state.userInfo;
    // let isAuthenticated = userInfo?Object.keys(userInfo).length>0:false;
    // console.log("路由拦截是否有权限",isAuthenticated);
    // if (to.name !== 'Login' && !isAuthenticated) {
    //     next({ name: 'Login' })
    // }else{
    //     next()
    // } 
    next()
  })
router.afterEach((to, from, failure) => {
  console.log("afterEach");
})
export default router