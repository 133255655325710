import { createStore } from "vuex"
import userInfo from "./modules/userInfoModule.js"
import active from "./modules/userInfoModule.js"

import createPersistedState from 'vuex-persistedstate'
const store = createStore({
    state(){
        return {
            aa:12
        }
    },
    modules:{
        userInfo,
        active
    },
    plugins:[
        // veux持久化配置
        // createPersistedState()
        createPersistedState({
            key: 'marketVuexData',
            paths: ['userInfo.userInfo']
        })
    ]
})
export default store