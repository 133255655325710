/**
 * @function 美化log
 * @param {Number} type 日志类型
 * @param {String} val 日志
 */
function createColorLogIns() {
    const keyArr = ['info', 'success', 'warning', 'error', 'highlight']
    // 0-普通蓝 1-成功绿 2-警告黄 3-错误红 4-高亮粉
    const colorArr = [
        {
            color: '#2f54eb',
            background: '#f0f5ff',
            'border-color': '#adc6ff'
        },
        {
            color: '#357914',
            background: '#f6ffed',
            'border-color': '#b7eb8f'
        },
        {
            color: '#fa8c16',
            background: '#fff7e6',
            'border-color': '#ffd591'
        },
        {
            color: '#f5222d',
            background: '#fff1f0',
            'border-color': '#ffa39e'
        },
        {
            color: '#eb2f96',
            background: '#fff0f6',
            'border-color': '#ffadd2'
        }
    ]
    const resObj = {}
    keyArr.forEach((key, i) => {
        resObj[key] = function() {
           
            let [name,...val] = arguments;
            // if(typeof obj === 'string') {
            //     name = obj
            // } else {
            //     name = obj.name
            //     val = obj.val
            // }
            console.log(`%c ${name}`, `${Object.keys(colorArr[i]).map(key => `${key}: ${colorArr[i][key]};`).join(' ')} padding: 5px 0;`, ...val)
        }
    })
    return resObj;
}

export const colorLog = {
    ...createColorLogIns()
}
/**
 * @description 将字符串中的emjio😊表情删除
 * @param {*} str 
 */
export const replaceFace = function(str){
    str.replace(
            /(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g,
            ""
        );
    return str;
}

/**
 * @function 生成随机长度串
 */
export function randomString(length = 10) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_=-';
    // const chars = '123456789';
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
}

/**
 * 
 * @param {*} fn 
 * @param {*} delay 
 * @description 函数防抖（debounce） 是指在一定时间内，在动作被连续频繁触发的情况下，动作只会被执行一次，也就是说当调用动作过n毫秒后，才会执行该动作，若在这n毫秒内又调用此动作则将重新计算执行时间，所以短时间内的连续动作永远只会触发一次。
 * @abstract resize 事件
 */
export function _debounce(fn, delay) {
 
    var delay = delay || 200;
    var timer;
    return function () {
        var th = this;
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            fn.apply(th, args);
        }, delay);
    };
}
/**
 * 
 * @param {*} fn 
 * @param {*} delay 
 * @description 函数节流指一定时间内执行的操作只执行一次，也就是说即预先设定一个执行周期，当调用动作的时刻大于等于执行周期则执行该动作，然后进入下一个新周期，一个比较形象的例子是如果将水龙头拧紧直到水是以水滴的形式流出，那你会发现每隔一段时间，就会有一滴水流出。
 * @abstract scroll 事件，单位时间后计算一次滚动位置
 */
export function _throttle(fn, interval) {
    var last;
    var timer;
    var interval = interval || 200;
    return function () {
        var th = this;
        var args = arguments;
        var now = +new Date();
        if (last && now - last < interval) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                fn.apply(th, args);
            }, interval);
        } else {
            last = now;
            fn.apply(th, args);
        }
    }
}