<template>
  <div id="appBody">
    <div>
      <div class="navbar-box" :class="['navbar', { navbar_bg: is_bg }]">
        <div class="logo">
          <img class="logoImg" src="./assets/img/logo_t.png" alt="" />
        </div>
        <ul class="navmun">
          <li
            class="nav_li"
            v-for="item in nav"
            :key="item.id"
            :class="{ active: i === item.id }"
            style="cursor: pointer"
            @click="toPath(item)"
            @mouseenter="enter(item.id)"
            @mouseleave="leave()"
          >
            {{ item.name }}
            <ul v-if="item.subItems" class="subnebu">
              <li
                class="subItems_list"
                v-for="(itema, indexa) in item.subItems"
                :key="indexa"
                @click="subnebuBtn(item, itema)"
              >
                {{ itema.name }}
                <ul class="navChild" v-if="itema.child">
                  <li
                    class="navChild_list"
                    v-for="(itemaa, indexaa) in itema.child"
                    :key="indexaa"
                    @click.stop="toPathChild(itemaa.path)"
                  >
                    {{ itemaa.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <!-- <li class="nav_li">
            <span @click="changeLanguageen()" :class="{'Languageenactives' : LanguagezhShow===1}" style="cursor: pointer;">中</span>
            <span>|</span>
            <span @click="changeLanguagezh()" :class="{'Languageenactives' : LanguagezhShow===0}" style="cursor: pointer;">EN</span>
          </li> -->
        </ul>
      </div>
    </div>
    <div><router-view></router-view></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_bg: true,
      nav: [
        {
          id: 0,
          name: "首页",
          path: "HomePage",
          subItems: [],
        },
        {
          id: 1,
          name: "动作捕捉",
          path: "Dongzuobuzhuo",
          subItems: [],
        },
        {
          id: 2,
          name: "数字人虚拟直播",
          path: "Szzb",
          subItems: [],
        },
        {
          id: 3,
          name: "服务支持",
          path: "Fwzc",
          subItems: [],
        },
        {
          id: 4,
          name: "关于我们",
          path: "About",
          subItems: [
            {
              id: 6,
              name: "公司简介",
              path: "About",
            },
            {
              id: 7,
              name: "联系我们",
              path: "About",
            },
          ],
        },
      ],
      linkClick: "",
      i: 0,
      inx: 0, //导航选中的下标
    };
  },
  watch: {
    // 监听路由对象中的变化
    $route: function (to, from) {
      // 当路由发生变化时会调用这个函数
      // to 和 from 分别代表到达的路由和离开的路由
      console.log("Route changed from");
      console.log(to);
      console.log(from);
      console.log(this.nav);
      if (to != null && to.name) {
        this.nav.forEach((e, i) => {
          if (e.path == to.name) {
            console.log("----", e);
            this.inx = i;
            this.i = i;
          }
        });
      }
      // 在这里可以根据路由变化执行相应的逻辑
    },
  },
  methods: {
    //鼠标移入事件
    enter(index) {
      this.i = index;
    },
    //鼠标移出事件
    leave() {
      console.log(this.inx);
      this.i = this.inx;
    },
    toPath(item) {
      if (item.subItems && item.subItems.length > 0) {
        return;
      }
      this.i = item.id;
      this.inx = item.id;
      this.$router.push({
        name: item.path,
      });
      //滚动条回到顶部
      $("#appBody").animate({ scrollTop: 0 }, 0);
    },
    subnebuBtn(fa, item) {
      this.i = fa.id;
      this.inx = fa.id;
      if (item.path == "About") {
        if (this.$route.path != "/about") {
          this.$router.push({
            name: item.path,
          });
          if (item.id != 6) {
            setTimeout(() => {
              // 假设你想滚动到页面上的某个元素的位置
              var targetPosition = $("#about_lx").position().top;
              // 使用animate和scrollTop滚动到指定位置
              $("#appBody").animate(
                {
                  scrollTop: targetPosition,
                },
                "slow"
              ); // 'slow'定义动画执行的速度
            }, 200);
          }
        } else {
          if (item.id != 6) {
            setTimeout(() => {
              // 假设你想滚动到页面上的某个元素的位置
              var targetPosition = $("#about_lx").position().top;

              // 使用animate和scrollTop滚动到指定位置
              $("#appBody").animate(
                {
                  scrollTop: targetPosition,
                },
                "slow"
              ); // 'slow'定义动画执行的速度
            }, 200);
          }
        }
      }
    },
    scrolling(event) {
      if (event.target.scrollTop > 100) {
        this.is_bg = true;
      } else {
        this.is_bg = false;
      }
    },
  },
  mounted() {
    let body = document.getElementById("appBody");
    body.addEventListener("scroll", this.scrolling);
  },
  unmounted() {
    let body = document.getElementById("appBody");
    body.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style lang="scss">
html,
body,
html,
body,
#appBody,
#app {
  height: 100%;
  width: 100%;
}
#appBody {
  overflow-y: auto;
  overflow-x: hidden;
}
.el-select-v2__placeholder {
  font-size: 14px;
}
.navbar_bg {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.navbar {
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: fixed;
  z-index: 9999;
  top: 0px;
  right: 0px;
  font-family: PingFangSC-Regular;
  background: rgba(255, 255, 255, 0);
  .navbar-box {
    margin: 0 auto;
  }
  .navmun {
    list-style-type: none;
    text-align: right;
    margin-right: 100px;
  }

  .nav_li {
    display: inline-block;
    height: 80px;
    text-align: center;
    padding: 0 30px;
    font-size: 14px;
    color: #fff;
    position: relative;
  }
  .nav_li span {
    margin-left: 4px;
    display: inline-block;
    height: 54px;
  }
  .Languageenactives {
    border-bottom: 2px solid #ec5c20;
  }
  .logo {
    float: left;
    width: 113px;
    height: 30px;
    margin-top: 10px;
    margin-left: 100px;
    .logoImg {
      width: 113px;
      height: 30px;
    }
  }
  .active {
    border-bottom: 3px solid #ec5c20;
    /* position: relative; */
  }

  .subnebu {
    min-width: 178px;
    background: #000;
    color: #fff;
    position: absolute;
    top: 80px;
    left: 0px;
  }
  .subItems_list {
    text-align: left;
    line-height: 16px;
    padding-left: 12px;
    font-size: 14px;
    letter-spacing: 1px;
    margin-top: 20px;
    display: none;
  }
  .nav_li:hover .subItems_list {
    display: block;
  }

  .subItems_list:last-child {
    margin-bottom: 25px;
  }
  .subItems_list:hover {
    color: red;
  }
  .navChild {
    background: #000;
    position: absolute;
    top: 0;
    left: 178px;
    width: 388px;
    min-height: 240px;
    display: none;
    padding-left: 10px;
    color: #fff;
    font-size: 12px;
    border-left: 1px solid red;
  }
  .navChild_list {
    margin-top: 20px;
    line-height: 16px;
  }
  .navChild_list:last-child {
    margin-bottom: 25px;
  }
  .subItems_list:hover .navChild {
    display: block;
  }
  .navChild_list:hover {
    color: red;
  }
  .actives {
    display: block;
  }
  .activeChild {
    display: block;
  }
}
</style>
